import MissingToolMessage from './MissingToolMessage';

import InfoTool from './InfoTool';

import ApprovalReviewTool from '@acorns-ui/Tools/ApprovalReviewTool';
import ApprovalTool from '@acorns-ui/Tools/ApprovalTool';
import BarPlotTool from '@acorns-ui/Tools/BarPlotTool';
import DatasetFilterTool from '@acorns-ui/Tools/DatasetFilterTool';
import DatasetViewerTool from '@acorns-ui/Tools/DatasetViewerTool';
import DocumentFileTool from '@acorns-ui/Tools/DocumentFileTool';
import DeliverableTool from '@acorns-ui/Tools/DeliverableTool';
import DocumentTool from '@acorns-ui/Tools/DocumentTool';
import ContactsTool from '@acorns-ui/Tools/ContactsTool';
import DonutChartTool from '@acorns-ui/Tools/DonutChartTool';
import EventTool from '@acorns-ui/Tools/EventTool';
import FormTool from '@acorns-ui/Tools/FormTool';
import MonitoringElementsTool from '@acorns-ui/Tools/MonitoringElementsTool';
import NoteTool from '@acorns-ui/Tools/NoteTool';
import ParentCollectionTool from '@acorns-ui/Tools/ParentCollectionTool';
import PhotoPointTool from '@acorns-ui/Tools/PhotoPointTool';
import PlanningTool from '@acorns-ui/Tools/PlanningTool';
import CollectionSearchTool from '@acorns-ui/Tools/CollectionSearchTool';
import DatasetTool from '@acorns-ui/Tools/DatasetTool';

import ProjectInfoTool from '@acorns-ui/Tools/ProjectInfoTool';
import ContactInfoTool from '@acorns-ui/Tools/ContactInfoTool';
import RegionInfoTool from '@acorns-ui/Tools/RegionInfoTool';
import PropertyInfoTool from '@acorns-ui/Tools/PropertyInfoTool';
import IssueInfoTool from '@acorns-ui/Tools/IssueInfoTool';

import PropertyTool from '@acorns-ui/Tools/PropertyTool';
import ProjectTool from '@acorns-ui/Tools/ProjectTool';
import RestrictionsTool from '@acorns-ui/Tools/RestrictionsTool';
import ScatterPlotTool from '@acorns-ui/Tools/ScatterPlotTool';
import TableTool from '@acorns-ui/Tools/TableTool';
import TasksTool from '@acorns-ui/Tools/TasksTool';
import ProjectsTool from '@acorns-ui/Tools/ProjectsTool';
import MapTool from '@acorns-ui/Tools/MapTool';
import PlannedAccountsTool from '@acorns-ui/Tools/PlannedAccountsTool';
//import IntakeFormTool from '@acorns-ui/Tools/IntakeFormTool';
//import ParcelSelectorTool from '@acorns-ui/Tools/ParcelSelectorTool';
import CollectionPanelTool from '@acorns-ui/Tools/CollectionPanelTool';
import SiteVisitTool from '@acorns-ui/Tools/SiteVisitTool';
import IssueReviewTool from '@acorns-ui/Tools/IssueReviewTool';
import CalendarTool from '@acorns-ui/Tools/CalendarTool';

export default {
  props: {
    defaultTool: {
      type: String,
      default: 'MissingToolMessage',
    },
    testing: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    CollectionSearchTool,
    DatasetTool,
    DeliverableTool,
    MissingToolMessage,
    InfoTool,
    ProjectTool,
    CalendarTool,
    //IntakeFormTool,
    PlannedAccountsTool,
    IssueReviewTool,
    ApprovalTool,
    DatasetViewerTool,
    DocumentFileTool,
    DocumentTool,
    ContactsTool,
    EventTool,
    FormTool,
    NoteTool,
    ParentCollectionTool,
    PlanningTool,
    ProjectInfoTool,
    ContactInfoTool,
    PropertyTool,
    RestrictionsTool,
    TableTool,
    TasksTool,
    BarPlotTool,
    ScatterPlotTool,
    DonutChartTool,
    DatasetFilterTool,
    ApprovalReviewTool,
    MonitoringElementsTool,
    PhotoPointTool,
    PropertyInfoTool,
    RegionInfoTool,
    SiteVisitTool,
    MissingToolMessage,
    CollectionPanelTool,
    DeliverableTool,
    IssueInfoTool,
    MapTool,
  //  ParcelSelectorTool,
    ProjectsTool,
  }
};
