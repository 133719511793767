<template>
<div class="app-card">
  <h1>{{ label }}</h1>
  <h2>{{ repo }}</h2>
  <div
    v-for="d in deployments"
    :key="d.uid"
    class="deployment-item">
    <div class="label">
      {{ d.branch }} @ {{ d.url }}
    </div>
    <div class="actions">
      <a
        class="alert"
        v-if="d.deployed && !d.status"
        :title="`last attempted ${d.deployed}`"
        @click="install(d.uid)">
        failed
      </a>
      <a
        v-else-if="d.deployed"
        :title="`last deployed ${d.deployed}`"
        @click="install(d.uid)">
        {{ d.deployed | fromNow }}
      </a>
      <a
        v-if="!d.deployed"
        @click="install(d.uid)">
        deploy
      </a>
    </div>
  </div>
</div>
</template>

<script>
import { post } from '@/api';


export default {
  name: 'app-card',
  props: {
    label: {
      type: String,
      default: 'App Name'
    },
    repo: {
      type: String,
      default: 'Repo path'
    },
    deployments: {
      type: Array,
      default: ()=>([
        { uid: 1, url: 'www.talloaks.io', branch: 'master', last: '2018-05-02 15'},
        { uid: 2, url: 'staging.talloaks.io', branch: 'dev', last: '2018-10-02 08' }
      ])
    }
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  watch: {},
  computed: {},
  methods: {
    install(id){
      post(`deployments/${id}/install`);
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.app-card  {
  @include shadow;
  padding: 1rem;

  .alert {
    font-weight: 600;
    color: $red;
  }

  .deployment-item {
    @include row;
    .label {
      flex: 1;
    }
  }
}
</style>
