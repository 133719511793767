<template>
<PreviewLayout
  class="job-details"
  @close="$emit('close')"
  :loaded="loaded"
  >
  <div
    class="logs"
    slot="panels">
    <pre v-for="log in logs">
    {{ log }}
    </pre>

    <Alert
      v-if="error"
      type="error">
      <pre>
        {{ error }}
      </pre>
    </Alert>

  </div>

</PreviewLayout>
</template>

<script>
import PreviewLayout from '@acorns-ui/Layouts/PreviewLayout';
import Alert from '@acorns-ui/Text/Alert';
import RecordDataMixin from '@/mixins/RecordDataMixin';
import { get } from '@/utils';

export default {
  name: 'JobDetails',
  mixins: [
    RecordDataMixin,
  ],
  props: {
    collection: {
      type: String,
      default: 'jobs',
    },
  },
  data() {
    return {};
  },
  components: {
    PreviewLayout,
    Alert,
  },
  created() {},
  watch: {},
  computed: {
    logs(){
      return get(['results','log'], this.record) || [];
    },
    error(){
      const err = get(['results','error'], this.record);
      if(err){
        return err.replace(/\\n/g, "\n");
      }
    }
  },
  methods: { }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .job-details {
    @include pagesize;
  }
}

.job-details  {
  pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }

  .logs {
    padding: 1rem;
  }
}
</style>
