<template>
<PreviewLayout
  class="deployment-preview"
  @close="$emit('close')"
  :loaded="loaded"
  >

  <h1
    slot="label">
    {{ record.url }}
  </h1>

  <div
    slot="panels"
    class="section">
    <a
      v-if="record && record.jobs"
      v-for="j in record.jobs"
      :key="j.uid"
      @click="showJobDetails(j.uid)">
      <div
        class="job-item">
        <div class="header">
          {{ fix(j.finished) }} / {{ j.command }}
        </div>
        <div class="actions">
          {{ j.success ? ' - ' : 'failed' }}
        </div>
      </div>
    </a>
  </div>

  <Button
    slot="footer"
    type="action"
    @click="editRecord">
    Edit
  </Button>

  <Button
    slot="footer"
    type="save"
    @click="install">
    Deploy
  </Button>

  <Button
    slot="footer"
    type="delete"
    @click="remove">
    Remove
  </Button>

</PreviewLayout>
</template>

<script>
import { post } from '@/api';
import PreviewLayout from '@acorns-ui/Layouts/PreviewLayout';
import RecordDataMixin from '@/mixins/RecordDataMixin';
import Button from '@acorns-ui/Inputs/Button';
import moment from 'moment';

export default {
  name: 'DeploymentPreview',
  mixins: [
    RecordDataMixin,
  ],
  props: {
    collection: {
      type: String,
      default: 'deployments',
    },
  },
  data() {
    return {};
  },
  components: {
    PreviewLayout,
    Button,
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    fix(value, offset=-7) {
      var dt = moment(value,'YYYY-MM-DD HH:mm:ss');
      return dt.add(offset, 'hours').format('MMMM DD YYYY, h:mm a');
    },
    install(){
      post(`deployments/${this.id}/install`)
        .then( () => {
          this.$store.dispatch('deployments/load')
          this.getRecordData();
        });
    },
    remove(){
      post(`deployments/${this.id}/remove`)
        .then( () => {
          this.$store.dispatch('deployments/load')
          this.getRecordData();
        });
    },
    showJobDetails(id){
      this.$store.dispatch('PageModals/showModal', {
        component: 'JobDetails',
        params: { id }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .deployment-preview {
    @include formsize;
  }
}

.deployment-preview  {

  button {
    margin-right: 0.5rem;
  }

  .job-item {
    display: flex;
    padding: 0.5rem;
    border-bottom: $border-style;
    cursor: pointer;

    &:hover {
      @include clickable;
    }

    .header {
      flex: 1;
    }
    .actions {
      margin-left: auto;
    }
  }
}
</style>
