<template>
<PreviewLayout
  class="app-preview"
  @close="$emit('close')"
  :loaded="loaded"
  >

  <h1
    slot="label">
    {{ record.label }}
  </h1>

<div
  slot="panels"
  class="section">
  <a
    v-if="record && record.deployments"
    v-for="d in record.deployments"
    :key="d.uid"
    @click="showDeploymentPreview(d.uid)">
    <div
      class="deployment-item">
      <div class="header">
        {{ d.url }} / {{ d.branch }}
      </div>
      <div class="actions">
        {{ d.deployed | fromNow }}
      </div>
    </div>
  </a>
</div>

  <Button
    slot="footer"
    type="action"
    @click="editRecord">
    Edit
  </Button>

  <Button
    slot="footer"
    type="action"
    @click="newDeployment">
    Add
  </Button>

</Previewlayout>
</template>

<script>
import PreviewLayout from '@acorns-ui/Layouts/PreviewLayout';
import RecordDataMixin from '@/mixins/RecordDataMixin';
import Button from '@acorns-ui/Inputs/Button';

export default {
  name: 'AppPreview',
  mixins: [
    RecordDataMixin,
  ],
  props: {
  collection: {
      type: String,
      default: 'apps',
    },
  },
  data() {
    return {};
  },
  components: {
    PreviewLayout,
    Button,
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    showDeploymentPreview(id){
      this.$store.dispatch('PageModals/showModal', {
        component: 'DeploymentPreview',
        params: { id }
      })
    },
    newDeployment() {
      const app_id = this.id;
      this.$store.dispatch('PageModals/showModal', {
        component: 'CollectionForm',
        params: {
          collection: 'deployments',
          defaults: { app_id }
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .app-preview {
    @include formwidth;
  }
}

.app-preview  {
  .deployment-item {
    display: flex;
    padding: 0.5rem;
    border-bottom: $border-style;
    cursor: pointer;

    &:hover {
      @include clickable;
    }

    .header {
      flex: 1;
    }
    .actions {
      margin-left: auto;
    }
  }
}
</style>
