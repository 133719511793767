<template>
<div class="contact-menu">

  <div class="menu-list main-menu">
    <RouteMenuItem
      label="Home"
      description="Contact home"
      theme="tab"
      :exact="true"
      :route="`/contacts/${id}`"/>

    <RouteMenuItem
      label="Calendar"
      theme="tab"
      description="Contact events and timeline"
      :route="`/contacts/${id}/calendar#month`"/>

    <template
      v-for="collection in availableCollections">
      <RouteMenuItem
        v-if="collection!='deliverables'"
        theme="tab"
        :key="collection"
        :label="collection"
        :description="`Contact ${collection}`"
        :route="`/contacts/${id}/${collection}`"/>
    </template>
  </div>

  <div class="menu-list">
    <RouteMenuItem
      label="Search"
      theme="tab"
      description="Contact events and timeline"
      icon="search"
      :route="`/contacts/search`"/>
  </div>

</div>
</template>

<script>
import { get } from '@/utils';
import RouteMenuItem from '@acorns-ui/Menus/RouteMenuItem';
import MenuItem from '@acorns-ui/Menus/MenuItem';

export default {
  name: 'ContactMenu',
  props: {
    id: {
      type: [Number, String],
    },
  },
  components: {
    MenuItem,
    RouteMenuItem,
  },
  data() {
    return {
      reportMenuActive: false,
    };
  },
  computed: {
    collections() {
      const store = `contact${this.id}`;
      return get([store, 'collections'], this.$store.state);
    },
    availableCollections() {
      if (!this.collections) return [];
      return this.collections
        .map(d => d.object.key);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.contact-menu {
  @include row;

  .menu-list {
    @include row;
  }
  .main-menu {
    flex: 1;
  }
}
</style>
