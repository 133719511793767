<template>
<ObjectPageLayout
  class="contact"
  :include-sidebar="false">

  <div
    slot="pagebar"
    class="contact-toolbar-info">
    <Icon
      v-if="isClosed"
      @clicked="updateStatus">
      lock
    </Icon>
    <Icon
      v-if="!isClosed"
      @clicked="updateStatus">
      lock_open
    </Icon>

    <Breadcrumbs
      slot="pagebar"
      :breadcrumbs="breadcrumbs" />
  </div>

  <div
    slot="pagebar"
    class="actions">

    <Tag
      :class="{ clickable: editable, closed: isClosed, closable: closable }"
      theme="darken"
      @clicked="updateRole"
      :icon="closable || isClosed ? 'check_circle' : ''">
      {{ currentRoleLabel }}
    </Tag>


  </div>


  <ContactMenu
    slot="topbar"
    :id="id">
  </ContactMenu>

  <router-view
    name="workspace"
    slot="content"
    parent="contact"
    :parent-id="id"
    :id="id"
    :store="contactStore"
    :editable="editable"
    fkey="contact_id"
    />

</ObjectPageLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from '@/utils';
import Icon from '@acorns-ui/Icons/FontIcon';
import Tag from '@acorns-ui/Text/Tag'
import Breadcrumbs from '@acorns-ui/Text/Breadcrumbs';
import ObjectPageLayout from '@acorns-ui/Layouts/ObjectPageLayout';
import ObjectPageMixin from '@acorns-ui/Stores/Objects/Mixins/ObjectPageMixin';

// import textAvatar from '@acorns-ui/Avatars/TextAvatar';
// import PageLoadingIndicator from '../PageLoadingIndicator';
import ContactMenu from './ContactMenu';
// import TextAvatar from '@/components/Avatars/textAvatar';
// import contactInfo from './contactInfo';
// import EditableLookup from '@/components/Forms/inputs/editableLookup';
// import ModalContainer from '@/components/Layouts/ModalContainer';
// import StatusForm from '@/components/Forms/StatusForm';
// import LeadForm from '@/components/Forms/LeadForm';

export default {
  name: 'ContactPage',
  mixins: [
    ObjectPageMixin
  ],
  props: {
    parent: {
      type: String,
      default: 'contact'
    },
    id: String,
  },
  data() {
    return {
    };
  },
  components: {
    ObjectPageLayout,
    ContactMenu,
    Breadcrumbs,
    Icon,
    Tag,
  },
  created() {
    if(this.id) {
      this.getContact(this.id);
    }
  },
  watch: {
    id(a, b) {
      if(a) this.getContact(a);
      if(b) this.removeContact(b);
    },
  },
  computed: {
    ...mapState('navigation', [
      'breadcrumbs',
    ]),
    contactType(){
      return this.type||{};
    },
    typeLabel(){
      if(!this.type) return '';
      return this.type.label;
    },
    contactStore(){
      const store = `contact${this.id}`;
      return !!this.$store.state[store] ? store : 'contact';
    },
    currentRole(){
      return this.$store.getters[`${this.contactStore}/currentRole`]||{label: 'No current role'};
    },
    currentRoleLabel(){
      return this.currentRole.label;
    },
    show(){
      return this.loaded && !this.destroying;
    },
    loaded(){
      return get([this.contactStore, 'loaded'], this.$store.state);
    },
    label(){
      return get([this.contactStore, 'label'], this.$store.state);
    },
    description(){
      return get([this.contactStore, 'description'], this.$store.state);
    },
    additionalInfo(){
      return get([this.contactStore, 'additionalInfo'], this.$store.state);
    },
    isClosed(){
      return this.$store.getters[`${this.contactStore}/isClosed`];
    },
    closable(){
      return this.$store.getters[`${this.contactStore}/closable`];
    },
    requiredDeliverablesOpen(){
      return this.$store.getters[`${this.contactStore}/requiredDeliverablesOpen`];
    },
    editable(){
      return this.$store.getters[`${this.contactStore}/editable`];
    },
    config(){
      return this.$store.getters[`${this.contactStore}/config`];
    },
    percentComplete(){
      return this.$store.getters[`${this.contactStore}/percentComplete`];
    },
    newStatusReady() {
      return this.newStatus.template_status_id && this.newStatus.comment;
    },
  },
  methods: {
    getContact(id){
      const key = this._uid;
      const collections = ['tasks','communications','projects']
      this.$store.dispatch('getContact', { id, key, collections })
        .then( d => {
          this.destroying = false;
          // build the checklist
          this.$store.commit('navigation/SET_PAGE_TITLE', this.label);
          this.$store.commit('navigation/SET_PAGE_BREADCRUMBS', [
            {
              title: this.label,
              path: `/contacts/${id}`,
              source: 'page'
            },
          ])
        })
    },
    removeContact(id){
      const key = this._uid;
      this.destroying = true;
      this.$store.dispatch('board/clear')
      this.$store.dispatch('destroyObject', {
        type: 'contact',
        id,
        key,
      });
    },
    updateRole(){

    },
    updateStatus(){

    },
    updateField(field, value) {
      this.$store.dispatch(`${this.contactStore}/updateField`, { [field]: value });
    },
    clickPropertyMethod(){
      const id = null;
      if(!this.property.label) {
        this.$store.dispatch(`${this.contactStore}/addProperty`, { id });
      } else {
        this.$router.push(`/${this.propertyLabelPlural}/${this.property.property_id}`)
      }
    },
    linkToUnit(id){
      this.$store.dispatch(`${this.contactStore}/setProperty`, { id })
    }
  }
};
</script>


<style lang="scss">
@import '~@/styles/variables';

.contact.object-page {

  .page-toolbar {
    background-color: $contact-color;
    color: $white;

    a {
      font-weight: 400;
    }

    .contact-toolbar-info {
      display: flex;
      flex:1;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.75rem;

      .issue-label {
        font-size: 1.5rem;
        padding-right: 10px;
        &.editable {
          cursor: pointer;
        }
      }
      .issue-type-label {
        font-size: 1.5rem;
        flex: 1;
      }
      .lead-tag {

      }
      .property-tag {
        min-width: 30rem;
      }
    }
  }

  .tag {
    &.clickable {
      cursor: pointer;
    }
    &.closable {
      .icon {
        color: orange !important;
      }
    }
    &.closed {
      .icon {
        color: green !important;
      }
    }
  }


  .contact-toolbar-info {
    display: flex;

    .contact-label {
      // font-size: 24px;
      // line-height: 24px;
      padding-right: 10px;
    }
  }
}

</style>
