//import * as routes from './genericRoutes';
import ModuleRoute from '@acorns-ui/Modules/CollectionModule/ModuleRoute';
import CollectionWorkspaceRoute from '@acorns-ui/Workspaces/CollectionWorkspace/CollectionWorkspaceRoute';

//const baseStore = 'project';

//const dataRoute = routes.data(baseStore);
const calendarRoute = {
  path: 'calendar',
  components: {
    workspace: require('@acorns-ui/Workspaces/CalendarWorkspace').default
  },
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'calendar' }
    },
  },
  props: { workspace: true },
};

const boardsRoute = {
  path: '',
  alias: 'home',
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'home' }
    },
  },
  components: {
    workspace: require('@acorns-ui/Workspaces/BoardWorkspace').default
  },
  props: {
    workspace: true
  },
};

const mapRoute = {
  path: 'map',
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'map' }
    },
  },
  components: {
    workspace: require('@acorns-ui/Workspaces/MapWorkspace').default
  },
  props: { workspace: true },
  children: [
    {
      path: 'features/:prid',
      component: require('@/components/Previews/PreviewFactory').default,
      props: ({ params }) => ({ ...params, dataset: 'features'})
    },
    {
      path: 'projectfeatures/:ofid',
      component: require('@acorns-ui/Previews/ObjectFeatureDetails').default,
      props: ({ params }) => ({ ...params , resource: 'projectfeatures' })
    },
  ],
};



const contactsRoute = CollectionWorkspaceRoute({
  collection: 'contacts',
  grid: require('@acorns-ui/Grids/ContactsGrid').default,
  preview: 'contact-preview',
});

const messagesRoute = CollectionWorkspaceRoute({
  collection: 'communications',
  grid: require('@acorns-ui/Grids/MessagesGrid').default,
  preview: 'messages-preview',
});

const tasksRoute = CollectionWorkspaceRoute({
  collection: 'tasks',
  grid: require('@acorns-ui/Grids/TasksGrid').default,
  preview: 'task-preview',
});

const notesRoute = CollectionWorkspaceRoute({
  collection: 'notes',
  grid: require('@acorns-ui/Grids/NotesGrid').default,
  preview: 'notes-preview',
});

const documentsRoute = CollectionWorkspaceRoute({
  collection: 'documents',
  grid: require('@acorns-ui/Grids/DocumentsGrid').default,
  preview: 'documents-preview',
});

const issuesRoute = CollectionWorkspaceRoute({
  collection: 'issues',
  grid: require('@acorns-ui/Grids/IssuesGrid').default,
  preview: 'issues-preview',
});


const adminRoute = {
  path: 'admin',
  components: {
    workspace: require('./PropertyAdminWorkspace').default
  },
  meta: { title: 'Admin' },
  props: { workspace: true },
};


const route = ModuleRoute({
  module: 'properties',
  home: require('./PropertiesHome').default,
  individual: require('./PropertyPage').default,
  collections: [
    adminRoute,
    boardsRoute,
    calendarRoute,
    mapRoute,
    tasksRoute,
    issuesRoute,
    notesRoute,
    messagesRoute,
    contactsRoute,
    documentsRoute,
  ]
});

//console.log(route);

export default route;
