//import * as routes from './genericRoutes';
import ModuleRoute from '@acorns-ui/Modules/CollectionModule/ModuleRoute';
import CollectionWorkspaceRoute from '@acorns-ui/Workspaces/CollectionWorkspace/CollectionWorkspaceRoute';
//const baseStore = 'project';

//const dataRoute = routes.data(baseStore);
const calendarRoute = {
  path: 'calendar',
  components: {
    workspace: require('@acorns-ui/Workspaces/CalendarWorkspace').default
  },
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'calendar' }
    },
  },
  props: { workspace: true },
};
//const activityRoute = routes.activity(baseStore);
const boardsRoute = {
  path: '',
  alias: 'home',
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'home' }
    },
  },
  components: {
    workspace: require('@acorns-ui/Workspaces/BoardWorkspace').default
  },
  props: {
    workspace: true
  },
};
//const accountsRoute = routes.accounts(baseStore);
//const datasetsRoute = routes.datasets(baseStore);


const contactsRoute = CollectionWorkspaceRoute({
  collection: 'contacts',
  grid: require('@acorns-ui/Grids/ContactsGrid').default,
  preview: 'contact-preview',
});

const messagesRoute = CollectionWorkspaceRoute({
  collection: 'communications',
  grid: require('@acorns-ui/Grids/MessagesGrid').default,
  preview: 'messages-preview',
});

const tasksRoute = CollectionWorkspaceRoute({
  collection: 'tasks',
  grid: require('@acorns-ui/Grids/TasksGrid').default,
  preview: 'task-preview',
});

const notesRoute = CollectionWorkspaceRoute({
  collection: 'notes',
  grid: require('@acorns-ui/Grids/NotesGrid').default,
  preview: 'notes-preview',
});

const documentsRoute = CollectionWorkspaceRoute({
  collection: 'documents',
  grid: require('@acorns-ui/Grids/DocumentsGrid').default,
  preview: 'documents-preview',
});

const issuesRoute = CollectionWorkspaceRoute({
  collection: 'issues',
  grid: require('@acorns-ui/Grids/IssuesGrid').default,
  preview: 'issues-preview',
});

const projectsRoute = CollectionWorkspaceRoute({
  collection: 'projects',
  grid: require('@acorns-ui/Grids/ProjectsGrid').default,
  preview: "project-preview",
});

const route = ModuleRoute({
  module: 'contacts',
  individual: require('./ContactPage').default,
  collections: [
    //adminRoute,
    boardsRoute,
    calendarRoute,
    tasksRoute,
    issuesRoute,
    notesRoute,
    messagesRoute,
    contactsRoute,
    projectsRoute,
  ]
});

//console.log(route);

export default route;
