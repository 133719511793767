<template>
<div class="type-menu">

  <div class="menu-list main-menu">

    <RouteMenuItem
      label="Home"
      description="Type home"
      theme="tab"
      :exact="true"
      :route="`/types/${id}`"/>

    <RouteMenuItem
      label="Calendar"
      theme="tab"
      description="Type events and timeline"
      :route="`/types/${id}/calendar#month`"/>

    <RouteMenuItem
      v-if="isSpatialProject"
      label="Map"
      theme="tab"
      description="Type map"
      :route="`/types/${id}/map`"/>

    <RouteMenuItem
      theme="tab"
      v-for="collection in availableCollections"
      :key="collection"
      :label="collection"
      :description="`Type ${collection}`"
      :route="`/types/${id}/${collection}`"/>

    <RouteMenuItem
      :label="propertyLabelPlural"
      theme="tab"
      description="Search the type projects"
      :route="`/types/${id}/search`"/>


  </div>

  <div class="menu-list">
    <RouteMenuItem
      label="Admin"
      theme="tab"
      description="Type admin"
      icon="settings"
      :route="`/types/${id}/admin`"/>
  </div>


</div>
</template>

<script>
import { get } from '@/utils';
import { mapState } from 'vuex';
import RouteMenuItem from '@acorns-ui/Menus/RouteMenuItem';
import MenuItem from '@acorns-ui/Menus/MenuItem';

export default {
  name: 'TypeMenu',
  props: {
    id: {
      type: [Number, String],
    },
  },
  components: {
    MenuItem,
    RouteMenuItem,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('Global',[
      'propertyLabelPlural',
      'propertyLabelSlug',
    ]),
    collections() {
      const store = `type${this.id}`;
      return get([store, 'collections'], this.$store.state);
    },
    availableCollections() {
      if (!this.collections) return [];
      return this.collections
        .map(d => d.object.key);
    },
    isSpatialProject(){
      const store = `type${this.id}`;
      return get([store, 'isSpatialProject'], this.$store.state);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.type-menu {
  @include row;

  .menu-list {
    @include row;
  }
  .main-menu {
    flex: 1;
  }

}
</style>
