import { get } from '@/utils';

export default {
  props: {
    collection: {
      type: String,
      required: true,
    },
    id: {
      type: [Number, String],
    },
  },
  created(){
    this.getRecordData();
  },
  watch: {
    id(a){
      if(a) this.getRecordData();
    }
  },
  computed: {
    record(){
      const uid = `u${this.id}`;
      const store = this.collection || this.store;
      return get([store, 'records', uid], this.$store.state) || {};
    },
    loaded(){
      return !!this.record;
    }
  },
  methods: {
    getRecordData(){
      const id = this.id;
      const collection = this.collection;
      return this.$store.dispatch(`${collection}/getRecord`, { id });
    },
    editRecord(){
      const id = this.id;
      const collection = this.collection;
      const callback = () => {
        this.$store.dispatch(`${collection}/load`);
        this.getRecordData();
      };
      this.$store.dispatch(`${collection}/editRecord`, { id, callback });
    }

  },
};
