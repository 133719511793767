<template>
<ObjectPageLayout
  class="type"
  :include-sidebar="false">

  <div
    slot="pagebar"
    class="type-toolbar-info">

    <Breadcrumbs
      slot="pagebar"
      :breadcrumbs="breadcrumbs" />
  </div>

  <TypeMenu
    slot="topbar"
    :id="id">
  </TypeMenu>

  <router-view
    name="workspace"
    slot="content"
    parent="type"
    :parent-id="id"
    :id="id"
    :editable="editable"
    fkey="type_id"
    />

</ObjectPageLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from '@/utils';
import Icon from '@acorns-ui/Icons/FontIcon';
import Tag from '@acorns-ui/Text/Tag'
import Breadcrumbs from '@acorns-ui/Text/Breadcrumbs';
import ObjectPageLayout from '@acorns-ui/Layouts/ObjectPageLayout';
import ObjectPageMixin from '@acorns-ui/Stores/Objects/Mixins/ObjectPageMixin';
import TypeMenu from './TypeMenu';

export default {
  name: 'TypePage',
  mixins: [
    ObjectPageMixin
  ],
  props: {
    parent: {
      type: String,
      default: 'type'
    },
    id: String,
  },
  data() {
    return {
    };
  },
  components: {
    ObjectPageLayout,
    TypeMenu,
    Breadcrumbs,
    Icon,
    Tag,
  },
  created() {
    if(this.id) {
      this.getType(this.id);
    }
  },
  watch: {
    id(a, b) {
      if(a) this.getType(a);
      if(b) this.removeType(b);
    },
  },
  computed: {
    ...mapState('navigation', [
      'breadcrumbs',
    ]),
    type(){
      return get([this.store, 'type'], this.$store.state);
    },
    loaded(){
      return get([this.store, 'loaded'], this.$store.state);
    },
    label(){
      return get([this.store, 'label'], this.$store.state);
    },
    description(){
      return get([this.store, 'description'], this.$store.state);
    },
    additionalInfo(){
      return get([this.store, 'additionalInfo'], this.$store.state);
    },
    typeStatusId(){
      return get(['uid'], this.status)
    },
    config(){
      return this.$store.getters[`${this.store}/config`];
    },
  },
  methods: {
    getType(id){
      const key = this._uid;
      this.$store.dispatch('getType', { id, key })
        .then( d => {
          this.destroying = false;
          this.$store.commit('navigation/SET_PAGE_TITLE', this.label);
          this.$store.commit('navigation/SET_PAGE_BREADCRUMBS', [
            {
              title: this.label,
              path: `/types/${id}`,
              source: 'page'
            },
          ])
        })
    },
    removeType(id){
      const key = this._uid;
      this.destroying = true;
      this.$store.dispatch('board/clear')
      this.$store.dispatch('destroyObject', {
        type: 'type',
        id,
        key,
      });
    },
    updateField(field, value) {
      this.$store.dispatch(`${this.store}/updateField`, { [field]: value });
    },
  }
};
</script>


<style lang="scss">
@import '~@/styles/variables';

.type.object-page {

 .page-toolbar {
     background-color: $project-color;
     color: $white;

     a {
      font-weight: 400;
    }

    .type-toolbar-info {
      display: flex;
      flex:1;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.75rem;

      .issue-label {
        font-size: 1.5rem;
        padding-right: 10px;
        &.editable {
          cursor: pointer;
        }
      }
      .issue-type-label {
        font-size: 1.5rem;
        flex: 1;
      }
      .lead-tag {

      }
      .property-tag {
        min-width: 30rem;
      }
    }
  }

  .tag {
    &.clickable {
      cursor: pointer;
    }
    &.closable {
      .icon {
        color: orange !important;
      }
    }
    &.closed {
      .icon {
        color: green !important;
      }
    }
  }


  .type-toolbar-info {
    display: flex;

    .type-label {
      // font-size: 24px;
      // line-height: 24px;
      padding-right: 10px;
    }
  }
}

</style>
