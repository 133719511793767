<template>
<WorkspaceLayout
  class="deployments-page has-sidebar">

  <RowsContainer
    class="deployment-rows">
    <DeploymentRow
      v-for="a in deployments"
      :key="a.uid"
      :id="a.uid"
      :url="a.url"
      :repo="a.repo"
      :branch="a.branch"
      :deployed="a.deployed"
      :deployed-on="a.deployed_on"
      :pending-on="a.pending_on"
      @click="pushDetailsRoute"
      />
  </RowsContainer>

  <router-view
    slot="details"
    name="details"
    @close="closeDetailsRoute"/>

</WorkspaceLayout>
</template>
<script>

import WorkspaceLayout from '@acorns-ui/Layouts/WorkspaceLayout';
import RowsContainer from '@acorns-ui/Layouts/RowsContainer';
import DeploymentRow from '@/components/Rows/DeploymentRow';

export default {
  name: 'deployments-page',
  props: {},
  data() {
    return {};
  },
  components: {
    WorkspaceLayout,
    RowsContainer,
    DeploymentRow,
  },
  created() {
    this.$store.dispatch('deployments/getCollectionData', {});
  },
  watch: {},
  computed: {
    deployments(){
      return this.$store.getters['deployments/data'];
    }
  },
  methods: {
    pushDetailsRoute(id){
      this.$router.push(`/deployments/${id}`)
    },
    closeDetailsRoute(){
      this.$router.push(`/deployments`)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.deployments-page  {
  .deployment-rows {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
