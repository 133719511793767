<template>
<div class="ProjectsHome">
  <GridContainer>
    <TemplateCard
      class="card"
      v-for="t in templates"
      :key="t.template_id"
      :id="t.template_id"
      :label="t.label"
      :statuses="t.template_statuses_list"
      />
  </GridContainer>
</div>
</template>

<script>
import { fetch } from '@/api';
import GridContainer from '@acorns-ui/Layouts/GridContainer';
import TemplateCard from '@acorns-ui/Cards/TemplateCard';

export default {
  name: 'ProjectsHome',
  props: {},
  data() {
    return {
      templates: [],
    };
  },
  components: {
    GridContainer,
    TemplateCard,
  },
  created() {
    this.getTemplateSummary();
  },
  watch: {},
  computed: {},
  methods: {
    getTemplateSummary(){
      fetch('templates/summary')
      .then( res => this.templates = res);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.ProjectsHome  {
  padding: 1rem 20rem;
  margin: 0 auto;
  .card {
    width: 17.5rem;
    @include shadow;
    margin: 0 1rem 1rem 0;
  }
}
</style>
