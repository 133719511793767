<template>
<ObjectPageLayout
  class="property"
  :include-sidebar="false">

  <div
    slot="pagebar"
    class="property-toolbar-info">

    <Icon
      v-if="!editable">
      lock
    </Icon>
    <Icon
      v-if="editable">
      lock_open
    </Icon>

    <Breadcrumbs
      slot="pagebar"
      :breadcrumbs="breadcrumbs" />
  </div>

    <div
      slot="pagebar"
      class="actions">

      <Tag
        theme="darken"
        :title="propertyLeadTitle"
        @clicked="updateLead"
        icon="face">
        {{ lead ? lead.label : 'No lead' }}
      </Tag>

      <Tag
        class="clickable"
        @clicked="updateOwner"
        theme="darken"
        icon="home_work">
        {{ owner ? owner.label : 'Owner not set' }}
      </Tag>


      <Tag
        class="clickable"
        @clicked="updateStatus"
        theme="darken"
        :title="propertyStatusTitle"
        icon="account_tree">
        {{ propertyStatusLabel }}
      </Tag>

    </div>


  <PropertyMenu
    slot="topbar"
    :id="id">
  </PropertyMenu>

  <!-- <ProjectSidebar -->
  <!--   slot="sidebar" -->
  <!--   :store="store"> -->
  <!-- </ProjectSidebar> -->

  <router-view
    name="workspace"
    slot="content"
    parent="property"
    :parent-id="id"
    :id="id"
    :store="store"
    :editable="editable"
    fkey="property_id"
    />

</ObjectPageLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from '@/utils';
import Icon from '@acorns-ui/Icons/FontIcon';
import Tag from '@acorns-ui/Text/Tag'
import Breadcrumbs from '@acorns-ui/Text/Breadcrumbs';
import ObjectPageLayout from '@acorns-ui/Layouts/ObjectPageLayout';
import ObjectPageMixin from '@acorns-ui/Stores/Objects/Mixins/ObjectPageMixin';

// import textAvatar from '@acorns-ui/Avatars/TextAvatar';
// import PageLoadingIndicator from '../PageLoadingIndicator';
import PropertyMenu from './PropertyMenu';
// import TextAvatar from '@/components/Avatars/textAvatar';
// import projectInfo from './projectInfo';
// import EditableLookup from '@/components/Forms/inputs/editableLookup';
// import ModalContainer from '@/components/Layouts/ModalContainer';
// import StatusForm from '@/components/Forms/StatusForm';
// import LeadForm from '@/components/Forms/LeadForm';

export default {
  name: 'PropertyPage',
  mixins: [
    ObjectPageMixin
  ],
  props: {
    parent: {
      type: String,
      default: 'property'
    },
    id: String,
  },
  data() {
    return {
    };
  },
  components: {
    ObjectPageLayout,
    PropertyMenu,
    Breadcrumbs,
    Icon,
    Tag,
  },
  created() {
    if(this.id) {
      this.getProperty(this.id);
    }
  },
  watch: {
    id(a, b) {
      if(a) this.getProperty(a);
      if(b) this.removeProperty(b);
    },
  },
  computed: {
    ...mapState('Global',[
      'propertyLabelSingular',
      'propertyLabelSlug',
    ]),
    ...mapState('navigation', [
      'breadcrumbs',
    ]),
    template(){
      return get([this.store, 'template'], this.$store.state);
    },
    store(){
      const store = `property${this.id}`;
      return !!this.$store.state[store] ? store : 'property';
    },
    show(){
      return this.loaded && !this.destroying;
    },
    loaded(){
      return get([this.store, 'loaded'], this.$store.state);
    },
    label(){
      return get([this.store, 'label'], this.$store.state);
    },
    description(){
      return get([this.store, 'description'], this.$store.state);
    },
    additionalInfo(){
      return get([this.store, 'additionalInfo'], this.$store.state);
    },
    lead(){
      return get([this.store, 'lead'], this.$store.state);
    },
    geometry(){
      return get([this.store, 'geometry'], this.$store.state);
    },
    owner(){
      return get([this.store, 'owner'], this.$store.state);
    },
    propertyOwnerId(){
      return get(['property_owner_id'], this.owner)
    },
    propertyLeadId(){
      return get(['property_lead_id'], this.lead)
    },
    propertyLeadTitle(){
      if(!this.lead) return 'There is currently no lead for this property'
      const st = this.lead.start_date
      const notes = this.lead.notes || ''
      return `Started ${st}. ${notes}`;
    },
    propertyStatusLabel(){
      return get(['label'], this.status) || 'No status'
    },
    propertyStatusId(){
      return get(['property_status_id'], this.status)
    },
    propertyStatusTitle(){
      if(!this.status) return 'No status has been set';
      const st = this.status.start_date
      const notes = this.status.note || ''
      return `As of ${st}. ${notes}`;
    },
    propertyOwnerTitle(){
      if(!this.owner) return 'No owner has been set';
      const st = this.owner.start_date
      const notes = this.owner.note || ''
      return `As of ${st}. ${notes}`;
    },
    region(){
      return this.$store.state[this.store].region;
    },
    propertyStatusId(){
      return get(['uid'], this.status)
    },
    editable(){
      return this.$store.getters[`${this.store}/editable`];
    },
    propertyFeatureCollection(){
      return this.$store.getters[`${this.store}/propertyFeatureCollection`];
    },
    config(){
      return this.$store.getters[`${this.store}/config`];
    },
    newStatusReady() {
      return this.newStatus.template_status_id && this.newStatus.comment;
    },
  },
  methods: {
    getProperty(id){
      const key = this._uid;
      const collections = ['tasks', 'contacts']
      this.$store.dispatch('getProperty', { id, key, collections })
        .then( d => {
          this.$store.commit('navigation/SET_PAGE_TITLE', this.label);
          this.$store.commit('navigation/SET_PAGE_BREADCRUMBS', [
            {
              title: this.region.label,
              path: `/regions/${this.region.region_id}/`,
              source: 'page'
            },
            {
              title: this.label,
              path: `/${this.propertyLabelSlug}/${this.id}/home`,
              source: 'page'
            },
          ])
        })
    },
    removeProperty(id){
      const key = this._uid;
      this.destroying = true;
      this.$store.dispatch('board/clear')
      this.$store.dispatch('destroyObject', {
        type: 'property',
        id,
        key,
      });
    },
    updateStatus(){
      if(!this.isAdmin) return;
      this.showStatusForm=true;
    },
    updateLead(){
      const parent = this.parent;
      const parentId = this.id;
      const type = 'lead';
      const id = this.propertyLeadId;
      this.$store.dispatch('PageModals/showModal', {
        component: 'LeadForm',
        params: {
          parent,
          parentId,
          type,
          id,
        },
      })
    },
    updateOwner(){
      const parent = this.parent;
      const parentId = this.id;
      const id = this.propertyOwnerId;
      const type = 'owner';
      this.$store.dispatch('PageModals/showModal', {
        component: 'LeadForm',
        params: {
          parent,
          parentId,
          type,
          id,
        },
      })
    },
    updateStatus(){
      const parent = this.parent;
      const parentId = this.id;
      const id = this.propertyStatusId;
      this.$store.dispatch('PageModals/showModal', {
        component: 'StatusForm',
        params: {
          parent,
          parentId,
          type,
          id,
        },
      })
    },
  }
};
</script>


<style lang="scss">
@import '~@/styles/variables';

.property.object-page {

 .page-toolbar {
     background-color: $property-color;
     color: $white;

     a {
      font-weight: 400;
    }

    .property-toolbar-info {
      display: flex;
      flex:1;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1.75rem;

      .issue-label {
        font-size: 1.5rem;
        padding-right: 10px;
        &.editable {
          cursor: pointer;
        }
      }
      .issue-type-label {
        font-size: 1.5rem;
        flex: 1;
      }
      .lead-tag {

      }
      .property-tag {
        min-width: 30rem;
      }
    }
  }

  .tag {
    &.clickable {
      cursor: pointer;
    }
    &.closable {
      .icon {
        color: orange !important;
      }
    }
    &.closed {
      .icon {
        color: green !important;
      }
    }
  }


  .property-toolbar-info {
    display: flex;

    .property-label {
      // font-size: 24px;
      // line-height: 24px;
      padding-right: 10px;
    }
  }
}

</style>
