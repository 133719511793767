<template>
<div class="login">
  <LoginForm/>
</div>
</template>

<script>
import LoginForm from '@acorns-ui/Forms/LoginForm';

export default {
  name: 'Login',
  props: {},
  data() {
    return {};
  },
  components: {
    LoginForm,
  },
  created() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.login  {
  margin: 0 auto;
}
</style>
