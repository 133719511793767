

const verbs = {
  get: 'details/:id',
  put: 'save/:id',
  post: 'saveas',
  remove: 'remove/:id',
  unlink: 'unlink/:id',
  search: 'search',
  list: 'list',
  record: 'record/:id',
}

const verbs2 = {
  get: 'details/:id',
  put: 'save/:id',
  post: 'saveas',
  remove: 'remove/:id',
  unlink: 'unlink/:id',
  search: 'search',
  list: 'list',
  record: 'record/:id',
}

export const hoursEndpoints = {};


export const taskDeliverablesEndpoints = {
    uniqueId: 'task_deliverables_id',
    resource: 'taskdeliverables',
      ...verbs,
};

export const worksEndpoints = {
  uniqueId: 'works_id',
  resource: 'works',
    ...verbs,
};

export const groupEndpoints = {

};

export const templateEndpoints = {
  base: {
    uniqueId: 'template_id',
    resource: 'templates',
  },
  deliverables: {
    uniqueId: 'deliverable_id',
    resource: 'deliverables',
      ...verbs,
  },
  projects: {
    uniqueId: 'project_id',
    resource: 'projects',
      ...verbs,
  },
};

export const filesEndpoints = {
  base: {
    uniqueId: 'file_id',
    resource: 'files',
  },
  comments: {
    uniqueId: 'file_comment_id',
    resource: 'filenotes',
      ...verbs,
  },
  signatures: {
    uniqueId: 'file_signature_id',
    resource: 'filesignatures',
      ...verbs,
  },
};

export const communicationEndpoints = {
  base: {
    uniqueId: 'communication_id',
    resource: 'communications',
  },
  receipts: {
    uniqueId: 'receipt_id',
    resource: 'receipts',
      ...verbs,
  },
  messages: {
    uniqueId: 'message_id',
    resource: 'messages',
      ...verbs,
  },
  contacts: {
    uniqueId: 'contact_id',
    resource: 'contacts',
      ...verbs,
  },
};

export const contactEndpoints = {
  base: {
    uniqueId: 'contact_id',
    resource: 'contacts',
  },
  emails: {
    uniqueId: 'contact_email_id',
    resource: 'emails',
      ...verbs,
  },
  communications: {
    uniqueId: 'communication_id',
    resource: 'communications',
      ...verbs,
  },
  projects: {
    uniqueId: 'project_id',
    resource: 'contactprojects',
      ...verbs,
  },
  phones: {
    uniqueId: 'contact_phone_id',
    resource: 'phones',
      ...verbs,
  },
  addresses: {
    uniqueId: 'contact_address_id',
    resource: 'contactaddresses',
      ...verbs,
  },
  roles: {
    uniqueId: 'contact_role_id',
    resource: 'contactroles',
      ...verbs,
  },
  tasks: {
    uniqueId: 'task_id',
    resource: 'tasks',
      ...verbs,
  },
  organizations: {
    uniqueId: 'contact_group_id',
    resource: 'contactgroups',
      ...verbs,
  },
  contactGroups: {
    uniqueId: 'contact_group_id',
    resource: 'contactgroups',
      ...verbs,
  },
  groups: {
    uniqueId: 'contact_group_id',
    resource: 'contactgroups',
      ...verbs,
  },
  members: {
    uniqueId: 'contact_group_id',
    resource: 'contactmembers',
      ...verbs,
  },
};

export const projectEndpoints = {
    base: {
    uniqueId: 'project_id',
    resource: 'projects',
  },
  issues: {
    uniqueId: 'project_issue_id',
    resource: 'projectissues',
      ...verbs,
  },
  events: {
    uniqueId: 'project_event_id',
    resource: 'projectevents',
      ...verbs,
  },
  tasks: {
    uniqueId: 'project_task_id',
    resource: 'projecttasks',
      ...verbs,
  },
  contacts: {
    uniqueId: 'project_contact_id',
    resource: 'projectcontacts',
      ...verbs,
  },
  files: {
    uniqueId: 'project_file_id',
    resource: 'projectfiles',
      ...verbs,
  },
  documents: {
    uniqueId: 'project_document_id',
    resource: 'projectdocuments',
      ...verbs,
  },
  communications: {
    uniqueId: 'project_communication_id',
    resource: 'projectcommunications',
      ...verbs,
  },
  notes: {
    uniqueId: 'project_note_id',
    resource: 'projectnotes',
      ...verbs,
  },
  deliverables: {
    uniqueId: 'project_deliverable_id',
    resource: 'projectdeliverables',
      ...verbs,
  },
  checklists: {
    uniqueId: 'project_checklist_id',
    resource: 'projectchecklists',
      ...verbs,
  },
  parcels: {
    uniqueId: 'project_parcel_id',
    resource: 'projectparcels',
      ...verbs,
  },
  accounts: {
    uniqueId: 'project_account_id',
    resource: 'projectaccounts',
      ...verbs,
  },
};

export const issueEndpoints = {
  base: {
    uniqueId: 'issue_id',
    resource: 'issues',
  },
  tasks: {
    uniqueId: 'issue_task_id',
    resource: 'issuetasks',
      ...verbs,
  },
  taskDeliverables: {
    uniqueId: 'task_deliverable_id',
    resource: 'taskdeliverables',
      ...verbs,
  },
  projects: {
    uniqueId: 'issue_project_id',
    resource: 'issueprojects',
      ...verbs,
  },
  contacts: {
    uniqueId: 'issue_contact_id',
    resource: 'issuecontacts',
      ...verbs,
  },
  files: {
    uniqueId: 'issue_file_id',
    resource: 'issuefiles',
      ...verbs,
  },
  documents: {
    uniqueId: 'issue_document_id',
    resource: 'issuedocuments',
      ...verbs,
  },
  communications: {
    uniqueId: 'issue_communication_id',
    resource: 'issuecommunications',
      ...verbs,
  },
  notes: {
    uniqueId: 'issue_note_id',
    resource: 'issuenotes',
      ...verbs,
  },
  resolutions: {
    uniqueId: 'resolution_id',
    resource: 'issueproposals',
      ...verbs,
  },
  issuefeatures: {
    uniqueId: 'issue_feature_id',
    resource: 'issuefeatures',
      ...verbs,
  },
  features: { // this is for the map features
    uniqueId: 'feature_id',
    resource: 'features',
      ...verbs,
  },
};

export const propertyEndpoints = {
  base: {
    uniqueId: 'property_id',
    resource: 'properties',
  },
  history: {
    uniqueId: 'history_id',
    resource: 'propertyhistory',
      ...verbs,
  },
  issues: {
    uniqueId: 'property_issue_id',
    resource: 'propertyissues',
      ...verbs,
  },
  tasks: {
    uniqueId: 'task_id', //'property_task_id',
    resource: 'tasks', //'propertytasks',
      ...verbs,
  },
  monitoringElements: {
    uniqueId: 'monitoring_element_id',
    resource: 'propertymonitoringelements',
      ...verbs,
  },
  photoPoints: {
    uniqueId: 'photo_point_id',
    resource: 'photopoints',
      ...verbs,
  },
  cameraPoints: {
    uniqueId: 'camera_point_id',
    resource: 'camerapoints',
      ...verbs,
  },
  photoPointImages: {
    uniqueId: 'photo_point_image_id',
    resource: 'photopointimages',
      ...verbs,
  },
  projects: {
    uniqueId: 'property_project_id',
    resource: 'propertyprojects',
      ...verbs,
  },
  parcels: {
    uniqueId: 'parcel_id',
    resource: 'parcels',
      ...verbs,
  },
  observations: {
    uniqueId: 'observation_id',
    resource: 'propertyobservations',
      ...verbs,
  },
  contacts: {
    uniqueId: 'property_contact_id',
    resource: 'propertycontacts',
      ...verbs,
  },
  files: {
    uniqueId: 'property_file_id',
    resource: 'propertyfiles',
      ...verbs,
  },
  documents: {
    uniqueId: 'property_document_id',
    resource: 'propertydocuments',
      ...verbs,
  },
  communications: {
    uniqueId: 'property_communication_id',
    resource: 'propertycommunications',
      ...verbs,
  },
  notes: {
    uniqueId: 'property_note_id',
    resource: 'propertynotes',
      ...verbs,
  },
  restrictions: {
    uniqueId: 'restriction_id',
    resource: 'propertyrestrictions',
      ...verbs,
  },
  approvals: {
    uniqueId: 'approval_id',
    resource: 'approvals',
      ...verbs,
  },
  stands: {
    uniqueId: 'stand_id',
    resource: 'stands',
      ...verbs,
  },
  visits: {
    uniqueId: 'visit_id',
    resource: 'visits',
      ...verbs,
  },
  propertyStatuses: {
    uniqueId: 'property_status_id',
    resource: 'propertystatuses',
      ...verbs,
  },
  accounts: {
    uniqueId: 'property_account_id',
    resource: 'propertyaccounts',
      ...verbs,
  },
};

export const roleEndpoints = {
  base: {
    uniqueId: 'role_id',
    resource: 'roles',
  },
  issues: {
    uniqueId: 'issue_id',
    resource: 'issues',
      ...verbs,
  },
  tasks: {
    uniqueId: 'task_id',
    resource: 'tasks',
      ...verbs,
  },
  projects: {
    uniqueId: 'project_id',
    resource: 'projects',
      ...verbs,
  },
  properties: {
    uniqueId: 'property_id',
    resource: 'properties',
      ...verbs,
  },
  contacts: {
    uniqueId: 'contact_id',
    resource: 'contacts',
      ...verbs,
  },
};

export const regionEndpoints = {
  base: {
    uniqueId: 'region_id',
    resource: 'regions',
  },
  issues: {
    uniqueId: 'property_issue_id',
    resource: 'propertyissues',
      ...verbs,
  },
  tasks: {
    uniqueId: 'property_task_id',
    resource: 'propertytasks',
      ...verbs,
  },
  projects: {
    uniqueId: 'property_project_id',
    resource: 'propertyprojects',
      ...verbs,
  },
  // regions is nested so regions can have sub regions
  regions: {
    uniqueId: 'region_id',
    resource: 'regions',
      ...verbs,
  },
  properties: {
    uniqueId: 'property_id',
    resource: 'properties',
      ...verbs,
  },
  contacts: {
    uniqueId: 'property_contact_id',
    resource: 'propertycontacts',
      ...verbs,
  },
  parcels: {
    uniqueId: 'property_parcel_id',
    resource: 'propertyparcels',
      ...verbs,
  },
  restrictions: {
    uniqueId: 'restriction_id',
    resource: 'restrictions',
      ...verbs,
  },
};
