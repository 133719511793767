<template>
<WorkspaceLayout
  class="apps-page has-sidebar">

 <RowsContainer
    class="deployment-rows">
    <AppRow
      v-for="a in apps"
      :key="a.uid"
      :id="a.uid"
      :label="a.label"
      :repo="a.repo"
      :deployments="a.deployments ? a.deployments.length : 0"
      @click="pushDetailsRoute"
      />
  </RowsContainer>

  <router-view
    slot="details"
    name="details"
    @close="closeDetailsRoute"/>

</WorkspaceLayout>
</template>
<script>

import WorkspaceLayout from '@acorns-ui/Layouts/WorkspaceLayout';
import RowsContainer from '@acorns-ui/Layouts/RowsContainer';
import GridContainer from '@acorns-ui/Layouts/GridContainer';
import AppCard from '@/components/Cards/AppCard';
import AppRow from '@/components/Rows/AppRow';

export default {
  name: 'apps-page',
  props: {},
  data() {
    return {};
  },
  components: {
    WorkspaceLayout,
    GridContainer,
    RowsContainer,
    AppCard,
    AppRow,
  },
  created() {
    this.$store.dispatch('apps/getCollectionData', {});
  },
  watch: {},
  computed: {
    apps(){
      return this.$store.getters['apps/data'];
    }
  },
  methods: {
    pushDetailsRoute(id){
      this.$router.push(`/apps/${id}`)
    },
    closeDetailsRoute(){
      this.$router.push(`/apps`)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.apps-page  {
  .grid {
    margin: 0 auto;
    .card {
      width: calc(31% - 1rem);
      margin: 0 1rem 1rem 0;
    }
  }
}
</style>
