<template>
<a
  @click.stop="$emit('click', id)"
  :class="{ clickable: $listeners.click }">
  <div class="app-row">
    <div class="header">
      {{ label}} / {{ repo }}
    </div>
    <div class="actions">
      <Tag>
        {{ deployments }}
      </Tag>
    </div>
  </div>
</a>
</template>

<script>
import { post } from '@/api';
import Tag from '@acorns-ui/Text/Tag';

export default {
  name: 'app-row',
  props: {
    id: {
      type: [ Number, String ],
    },
    label: {
      type: String,
      // default: 'admin.talloaks.io'
    },
    repo: {
      type: String,
      // default: 'talloaks/talloaks-admin'
    },
    deployments: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  components: {
    Tag,
  },
  created() {},
  watch: {},
  computed: {

  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.clickable {
  .app-row {
    cursor: pointer;
    &:hover {
      background-color: rgba(0,0,0,0.05);
    }
  }
}

.app-row  {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border: $border-style;
  margin: 0.5rem 1rem;


  .alert {
    font-weight: 600;
    color: $red;
  }
  .notice {
    font-weight: 600;
    color: $green;
  }
  .info {
    font-weight: 600;
    color: steelblue;
  }
  .header {
    flex: 1;
  }
  .actions {
    margin-left: auto;
   // a {
   //   @include clickable;
   // }
  }
}
</style>
