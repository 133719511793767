<template>
<BoardWorkspace
  class="home-page"
  :parent="boardType"
  :id="currentBoardId"
  :url="url"
  :center="true">
</BoardWorkspace>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import BoardWorkspace from '@acorns-ui/Workspaces/BoardWorkspace';

export default {
  name: 'HomePage',
  props: {},
  data() {
    return {};
  },
  components: {
    BoardWorkspace,
  },
  created() {
    this.getRoleBoard(this.currentRoleId);
  },
  watch: {},
  computed: {
    ...mapGetters('user', [
      'cid',
      'id',
      'currentRole',
    ]),
    ...mapState('user', [
      'currentRoleId',
      'boardType',
    ]),
    currentBoardId(){
      if(this.boardType == 'role') {
        return this.currentRoleId;
      } else {
        // the user should not have an id
        return '';//this.id;
      }
    },
    url(){
      if(this.boardType == 'role') {
        return `role/userboard/${this.currentRoleId}`;
      } else {
        return `user/board`;
      }
    }
  },
  methods: {
    getRoleBoard(id) {
      this.$store.dispatch('getRole', { id })
        .then( res => this.loaded = true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.home-page  {

}
</style>
