<template>
<a
  @click.stop="$emit('click', id)"
  :class="{ clickable: $listeners.click }">
  <div class="deployment-row">
    <div class="header">
      {{ url }} / {{ repo }} / {{ branch }}
    </div>
    <div class="actions">
      <span
        class="notice"
        v-if="pendingOn"
        :title="`queued ${pendingOn}`">
        pending
      </span>
      <a
        class="alert"
        v-else-if="!deployed && deployedOn"
        :title="`last attempted ${deployedOn}`"
        @click="install">
        failed
      </a>
      <a
        v-else-if="deployed && deployedOn"
        :title="`last deployed ${deployedOn}`"
        @click="install">
        {{ deployedOn | fromNow }}
      </a>
      <a
        class="info"
        v-else-if="!deployedOn"
        @click="install">
        deploy
      </a>
    </div>
  </div>
</a>
</template>

<script>
import { post } from '@/api';

export default {
  name: 'deployment-row',
  props: {
    id: {
      type: [ Number, String ],
    },
    url: {
      type: String,
      // default: 'admin.talloaks.io'
    },
    repo: {
      type: String,
      // default: 'talloaks/talloaks-admin'
    },
    branch: {
      type: String,
      // default: 'master'
    },
    deployed: {
      type: Boolean,
      default: false
    },
    deployedOn: {
      type: String,
      //default: '1974-07-24 12:00'
    },
    pendingOn: {
      type: String,
      default: '1974-07-24 12:00'
    }
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  watch: {},
  computed: {

  },
  methods: {
    install(){
     // post(`deployments/${this.id}/install`)
     //   .then( () => this.$store.dispatch('deployments/load'));
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.clickable {
  .deployment-row {
    cursor: pointer;
    &:hover {
      background-color: rgba(0,0,0,0.05);
    }
  }
}

.deployment-row  {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border: $border-style;
  margin: 0.5rem 1rem;


  .alert {
    font-weight: 600;
    color: $red;
  }
  .notice {
    font-weight: 600;
    color: $green;
  }
  .info {
    font-weight: 600;
    color: steelblue;
  }
  .header {
    flex: 1;
  }
  .actions {
    margin-left: auto;
   // a {
   //   @include clickable;
   // }
  }
}
</style>
