import Vue from 'vue';
import Router from 'vue-router';
import globalRouteGuard from './routeGuard';
//import globalRouteGuard from '@acorns-ui/Router/RouteGuard';

import LoginRoute from './LoginRoute';
import ModuleRoute from '@acorns-ui/Modules/CollectionModule/ModuleRoute';
import CollectionPage from '@acorns-ui/Modules/CollectionModule/CollectionPage';

import templateAdminRoutes from '@acorns-ui/Modules/ProjectModule/TemplateAdminRoutes';
import projectRoutes from '@acorns-ui/Modules/ProjectModule/ProjectRoutes';
import templateRoutes from '@acorns-ui/Modules/ProjectModule/TemplateRoutes';
import AdminRoutes from '@acorns-ui/Modules/AdminModule/AdminRoutes';
import LibraryRoutes from '@acorns-ui/Modules/LibraryModule/LibraryRoutes';

import AppsPage from '@/views/AppsPage';
import AppPreview from '@/components/Previews/AppPreview';
import DeploymentsPage from '@/views/DeploymentsPage';
import DeploymentPreview from '@/components/Previews/DeploymentPreview';

import HomePage from '@/views/HomePage';

import ContactsRoutes from '@/views/ContactsModule/ContactsRoutes';
import PropertyRoutes from '@/views/PropertyModule/PropertyRoutes';
import PropertyTypeRoutes from '@/views/PropertyModule/TypeRoutes';


const TasksRoutes = ModuleRoute({
  module: 'tasks',
  pkey: 'task_id',
});

const CommunicationsRoutes = ModuleRoute({
  module: 'communications',
  pkey: 'communication_id',
  title: 'Communications'
});

const DocumentsRoutes = ModuleRoute({
  module: 'documents',
  pkey: 'document_id',
  title: 'Documents'
});

const HoursRoutes = ModuleRoute({
  module: 'hours',
  pkey: 'hour_id',
  title: 'Hours'
});

const FilesRoutes = ModuleRoute({
  module: 'files',
  pkey: 'file_id',
  title: 'Files',
  preview: require('@acorns-ui/Previews/FilePreview').default,
});


Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    LoginRoute,
    AdminRoutes,
    PropertyRoutes,
    PropertyTypeRoutes,
    projectRoutes,
    templateAdminRoutes,
    templateRoutes,
    ContactsRoutes,
    CommunicationsRoutes,
    DocumentsRoutes,
    HoursRoutes,
    FilesRoutes,
    TasksRoutes,
    LibraryRoutes,
    {
      path: '/',
      name: 'home',
      components: {
        page: HomePage
      },
    },
    {
      path: '/apps',
      components: { page: AppsPage },
      props: {
        page: true
      },
      children: [
        {
          path: ':id',
          components: { details: AppPreview },
          props: {
            details: true
          },
        },
      ]
    },
    {
      path: '/deployments',
      components: { page: DeploymentsPage },
      props: {
        page: true
      },
      children: [
        {
          path: ':id',
          components: { details: DeploymentPreview },
          props: {
            details: true
          },
        },
      ]
    },
    {
      path: '/:module',
      components: { page: CollectionPage },
      props: {
        page: true
      },
    },
  ],
});

router.beforeEach(globalRouteGuard);

export default router;
