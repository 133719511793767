<template>
<WorkspaceLayout
  class="property-admin-workspace">

  <Alert
    v-if="isClosed"
    type="info">
    <Icon>
      lock
    </Icon>
    <div>
      This property is no longer active. To edit any of the data below you must first make it active again.
    </div>
  </Alert>

  <FormLayout
    class="details">

    <LabeledInput
      label="Label/Title"
      details="A unique name for this property.">
      <TextInput
        :view="inputView"
        :value="propertyLabel"
        @updated="updateField('label', $event)"/>
    </LabeledInput>


    <LabeledInput
      label="Description/Summary"
      details="A brief description or summary of the property">
      <TextareaInput
        :view="inputView"
        :value="propertyDescription"
        @updated="updateField('description', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Property Type"
      details="What type of property is this?">
      <p v-if="isClosed">
        {{ propertyTypeLabel }}
      </p>
      <DropdownInput
        v-if="!isClosed"
        :value="propertyTypeId"
        method="propertytypes/lookup"
        @updated="updateField('property_type_id', $event)"/>
    </LabeledInput>

    <LabeledInput
      label="Region"
      details="What region of property is this?">
      <p v-if="isClosed">
        {{ propertyRegionLabel }}
      </p>
      <DropdownInput
        v-if="!isClosed"
        :value="propertyRegionId"
        method="regions/lookup"
        @updated="updateField('region_id', $event)"/>
    </LabeledInput>

    <LabeledInput
      v-for="p in propertyAttributes"
      :key="p.uid"
      :label="p.label"
      :details="p.description">
      <components
        :is="p.filter_component"
        :name="p.key"
        :value="p.value"
        :required="p.required"
        v-bind="p.filter_config"
        @updated="updateAttributeField(p, $event)"/>
    </LabeledInput>

    <LabeledInput>
      Use the property type
      <router-link :to="`/property-types/${propertyTypeId}`">
        admin page
      </router-link>
      to modify which attributes this property has.
    </LabeledInput>

  </FormLayout>


  <FormLayout
    v-if="showDeleteForm"
    label="Delete this property"
    class="details">

    <Button
      v-if="!showDeleteForm"
      @click="showDeleteForm = true">
      Delete Property
    </Button>

    <LabeledInput
      label="This action cannot be undone"
      details="Confirm that you want to delete this property by typing confirm in the textbox below">
      <TextInput
        class="confirm-input"
        placeholder="confirm"
        v-model="confirmString"/>
    </LabeledInput>


    <Button
      slot="buttons"
      :disabled="confirmString!='confirm'"
      @click="removeProperty">
      Delete Property
    </Button>
    <Button
      slot="buttons"
      type="cancel"
      @click="showDeleteForm = false">
      Cancel
    </Button>

  </FormLayout>

</WorkspaceLayout>
</template>

<script>
import { get } from '@/utils'
import WorkspaceLayout from '@acorns-ui/Layouts/WorkspaceLayout';
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import Button from '@acorns-ui/Inputs/Button';
import FormInputsMixin from '@acorns-ui/Forms/Mixins/FormInputsMixin';
import FormValidationMixins from '@acorns-ui/Forms/Mixins/FormValidationMixins';
import Alert from '@acorns-ui/Text/Alert';
import Icon from '@acorns-ui/Icons/FontIcon';

export default {
  name: 'PropertyAdminWorkspace',
  mixins: [
    FormValidationMixins,
    FormInputsMixin,
  ],
  props: {
    parent: String,
    id: [Number, String],
  },
  data() {
    return {
      confirmString: null,
      showDeleteForm: false
    };
  },
  components: {
    WorkspaceLayout,
    FormLayout,
    Button,
    Alert,
    Icon,
  },
  created() {},
  watch: {},
  computed: {
    parentStore(){
      return `${this.parent}${this.id}`;
    },
    isAdmin(){
      return this.$store.getters[`${this.parentStore}/isAdmin`];
    },
    isClosed(){
      return this.$store.getters[`${this.parentStore}/isClosed`];
    },
    propertyLabel(){
      return get([this.parentStore, 'label'], this.$store.state);
    },
    propertyDescription(){
      return get([this.parentStore, 'description'], this.$store.state);
    },
    propertyAttributes(){
      return get([this.parentStore, 'attributes'], this.$store.state);
    },
    propertyStartDate(){
      return get([this.parentStore, 'startDate'], this.$store.state);
    },
    propertyEndDate(){
      return get([this.parentStore, 'endDate'], this.$store.state);
    },
    propertyRegionId(){
      return get([this.parentStore, 'region', 'region_id'], this.$store.state);
    },
    propertyRegionLabel(){
      return get([this.parentStore, 'region', 'label'], this.$store.state);
    },
    propertyTypeId(){
      return get([this.parentStore, 'type', 'property_type_id'], this.$store.state);
    },
    propertyTypeLabel(){
      return get([this.parentStore, 'type', 'label'], this.$store.state);
    },
    inputView(){
      return this.isClosed ? 'display' : 'edit';
    }
  },
  methods: {
    updateField(key, value){
      this.$store.dispatch(`${this.parentStore}/updateField`, { [key]: value })
    },
    updateAttributeField(d, { key, value }){
      const { property_id, property_attribute_data_id, property_attribute_id } = d;
      this.$store.dispatch(`${this.parentStore}/updateAttributeData`, {
        property_attribute_data_id,
        property_attribute_id,
        key,
        value,
      })
        .then( res => {
          this.editing = { ...this.editing, [key]: false };
        });

     // this.$store.dispatch(`${this.parentStore}/updateAttributeData`, { id, value })
    },
    removeProperty(){
      this.$store.dispatch(`${this.parentStore}/remove`)
        .then( res => {
          this.$router.push('/')
        })
        .catch( err => this.error = err)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.property-admin-workspace  {

  .form.details {
    width: 60rem;
    margin: 0 auto;

    .memo-field {
      min-height: 25rem;
    }
  }

  overflow: auto;

  .form-input-container {
    .message {
      font-weight: 400;
      font-size: 0.875rem;
    }
  }

  .delete-form {
    max-width: 40rem;
    border: 1px solid darkred;
    background-color: pink;
    color: darkred;
    padding: 0.75rem;
    font-weight: 600;
    .confirm-input {
      input {
        //height: 2rem;
        //font-size: 2rem;
      }
    }
  }
}
</style>
