//import * as routes from './genericRoutes';
import ModuleRoute from '@acorns-ui/Modules/CollectionModule/ModuleRoute';
import CollectionWorkspaceRoute from '@acorns-ui/Workspaces/CollectionWorkspace/CollectionWorkspaceRoute';
//const baseStore = 'project';

const boardsRoute = {
  path: '',
  alias: 'home',
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'home' }
    },
  },
  components: {
    workspace: require('@acorns-ui/Workspaces/BoardWorkspace').default
  },
  props: {
    workspace: true
  },
};

const calendarRoute = {
  path: 'calendar',
  components: {
    workspace: require('@acorns-ui/Workspaces/CalendarWorkspace').default
  },
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'calendar' }
    },
  },
  props: { workspace: true },
};

const mapRoute = {
  path: 'map',
  meta: {
    breadcrumbs: {
      source: 'workspace',
      data: { title:  'map' }
    },
  },
  components: {
    workspace: require('@acorns-ui/Workspaces/MapWorkspace').default
  },
  props: { workspace: true },
  children: [
    {
      path: 'features/:prid',
      component: require('@/components/Previews/PreviewFactory').default,
      props: ({ params }) => ({ ...params, dataset: 'features'})
    },
    {
      path: 'projectfeatures/:ofid',
      component: require('@acorns-ui/Previews/ObjectFeatureDetails').default,
      props: ({ params }) => ({ ...params , resource: 'projectfeatures' })
    },
  ],
};


const route = ModuleRoute({
  module: 'property-types',
  individual: require('./TypePage').default,
  collections: [
    boardsRoute,
    calendarRoute,
    mapRoute,
  ]
});

//console.log(route);

export default route;
