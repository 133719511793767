<template>
<div class="property-menu">

  <div class="menu-list main-menu">
    <RouteMenuItem
      label="Home"
      description="Property home"
      theme="tab"
      :exact="true"
      :route="`/properties/${id}`"/>

    <RouteMenuItem
      label="Calendar"
      theme="tab"
      description="Property events and timeline"
      :route="`/properties/${id}/calendar#month`"/>

    <RouteMenuItem
      label="Map"
      theme="tab"
      description="Property map"
      :route="`/properties/${id}/map`"/>

    <RouteMenuItem
      theme="tab"
      v-for="collection in availableCollections"
      :key="collection"
      :label="collection"
      :description="`Property ${collection}`"
      :route="`/properties/${id}/${collection}`"/>

  </div>

  <div class="menu-list">
    <RouteMenuItem
      label="Search"
      theme="tab"
      description="Contact events and timeline"
      icon="search"
      :route="`/properties/search`"/>
    <RouteMenuItem
      label="Admin"
      theme="tab"
      description="Property admin"
      icon="settings"
      :route="`/properties/${id}/admin`"/>
  </div>


</div>
</template>

<script>
import { get } from '@/utils';
import RouteMenuItem from '@acorns-ui/Menus/RouteMenuItem';
import MenuItem from '@acorns-ui/Menus/MenuItem';

export default {
  name: 'PropertyMenu',
  props: {
    id: {
      type: [Number, String],
    },
  },
  components: {
    MenuItem,
    RouteMenuItem,
  },
  data() {
    return {
      reportMenuActive: false,
    };
  },
  computed: {
    collections() {
      const store = `property${this.id}`;
      return get([store, 'collections'], this.$store.state);
    },
    availableCollections() {
      if (!this.collections) return [];
      return this.collections
        .map(d => d.object.key);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.property-menu {
  @include row;

  .menu-list {
    @include row;
  }
  .main-menu {
    flex: 1;
  }

  .report-menu {
    color: $white;
    .label {
      color: $white;
    }
    .report-dropdown {
      background-color: $white;
      color: $black;
      width: 10rem;
      padding: 0.25rem;
    }
  }

  .collections-menu {
    color: $white;
    .label {
      color: $white;
    }
    .collections-dropdown {
      background-color: $white;
      color: $black;
      width: 10rem;
      padding: 0.25rem;
    }
  }
}
</style>
